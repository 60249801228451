// General customizations
@font-face {
    font-family: 'Droid Serif';
    src: url('../fonts/DroidSerif-Regular.ttf');
}

.w-95 {
    width: 95%;
}

.form-check-input:checked {
    background-color: $orange;
    border-color: $orange;
}

.form-control:focus {
    border-color: $orange;
    box-shadow: $orange;
}

.atom-btn-secondary {
    background-color: $black;
    border-color: $black;
    color: $gray-text;

    &:hover {
        background-color: $orange;
        border-color: $orange;
        color: $white;

        i {
            &:hover {
                color: $white;
            }
        }
    }
}

.text-primary {
    color: $red !important;
}

// Font customizations
h1, h2, h3, h4, h5, h6 {
    font-family: 'Droid Serif', Georgia, "Times New Roman", Times, serif;
}

.h1, .h2, .h3, .h4, .h5, .h6,
#heading-adv-search, form h5,
footer h3, .title h1, .title h3,
.static-container h3 {
    font-family: "Open Sans Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#action-icons h4 {
    font-style: italic;
}

// Header customizations
header {
    background-color: $beige-bg;

    .logo {
        height: 150px;
    }

    .header-nav {
        height: auto;

        .nav-link {
            color: $black;
        }

        i {
            &:hover {
                color: $orange;
            }
        }
    }

    .navbar-toggler {
        max-height: fit-content;
        margin-left: 1rem;
    }

    @media (max-width: 992px) {
        .container-fluid {
            align-items: end !important;
            justify-content: center !important;

            .navbar-brand img {
                margin-right: 0 !important;
            }

            .header-nav {
                width: 100%;
            }
        }
    }
}

// Homepage customizations
body {
    background-color: $white;
}

.staticpage.home {
    #wrapper {
        padding-top: 0 !important;
        background-color: $white;

        #content {
            border: none;

            .splash-div {
                background-image: url('/plugins/arSuchB5Plugin/images/homepage-bg.jpg');
                background-size: cover;
                height: 400px;

                .title {
                    color: $white;
                    margin-right: 3rem;

                    h1 {
                        font-size: 5rem;
                        font-weight: 400;
                        text-transform: uppercase;
                        margin-bottom: 0;
                    }

                    h3 {
                        text-align: center;
                    }

                    @media (max-width: 1200px) {
                        h1 {
                            font-size: 4rem;
                        }

                        h3 {
                            font-size: 1rem;
                        }
                    }

                    @media (max-width: 992px) {
                        width: 100%;
                        padding-left: 3rem;
                        margin-right: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        h1 {
                            font-size: 3.25rem;
                        }

                        h3 {
                            font-size: 1rem;
                        }
                    }

                    @media (max-width: 537px) {
                        h1 {
                            font-size: 2.25rem;
                        }
                    }
                }

                .card, .list-group-item {
                    background-color: $red;
                    border-color: $red;
                    color: $white;
                    padding: 0.25rem 0.5rem;

                    h2.h5 {
                        padding: 0.5rem !important;
                    }

                    a:hover {
                        background-color: $hover-white;
                    }
                }

                .splash-cont .card .list-group {
                    z-index: 1;
                }

                .card {
                    width: 300px;

                    @media (max-width: 1200px) {
                        width: 250px;
                    }

                    @media (max-width: 992px) {
                        .list-group {
                            font-size: 0.9rem;
                        }
                    }
                }

                @media (max-width:768px) {
                    height: 200px;

                    .splash-cont {
                        padding-top: 1rem !important;
                    }

                    .mission-div {
                        width: 100%;
                        justify-content: center !important;
                    }

                    .card {
                        display: none;
                    }

                    .title {
                        padding: 0;
                    }
                }
            }

            .mission-div {
                margin-top: -10rem;
                margin-bottom: 1rem;
                z-index: 0;

                .mission {
                    width: 33%;
                    background-color: $green-bg;
                    padding: 2.25rem;
                    line-height: 2.5rem;
                    font-size: 1.2rem;

                    a {
                        color: $gray;

                        &:hover {
                            color: $red;
                        }
                    }
                }

                @media (max-width: 1200px) {
                    margin-top: -8rem;

                    .mission {
                        width: 33%;
                        padding: 1.5rem;
                        line-height: 2rem;
                        font-size: 1.15rem;
                    }
                }

                @media (max-width: 992px) {
                    margin-top: -5rem;

                    .mission {
                        width: 40%;
                        font-size: 1rem;
                        margin-right: 0.25rem;
                    }
                }

                @media (max-width: 768px) {
                    .mission {
                        width: 100%;
                        text-align: center;
                        margin-right: 0;
                    }
                }
            }

            .newest {
                margin-top: -5rem;

                @media (max-width: 1261px) {
                    margin-top: -8rem;
                }

                @media (max-width: 1200px) {
                    margin-top: -4rem;
                }

                @media (max-width: 1144px) {
                    margin-top: -6rem;
                }

                @media (max-width: 1022px) {
                    margin-top: -7rem;
                }

                @media (max-width: 843px) {
                    margin-top: -9rem;
                }

                @media (max-width: 768px) {
                    margin-top: -1rem;
                }
            }

            #newest-additions {
                border: none;
                width: fit-content;
                z-index: 1;
                position: relative;

                .list-group {
                    padding-left: 3rem;

                    .list-group-item {
                        display: list-item !important;
                        border: none;
                        padding: 0.5rem;
                        text-decoration: underline;
                    }
                }

                @media (max-width: 768px) {
                    width: 100%;
                }
            }

            .carousel-cont {
                height: 400px;

                @media (max-width: 768px) {
                    height: unset;
                }
            }

            .carousel-div {
                @media (max-width:768px) {
                    flex-direction: column !important;
                }
            }

            #carousel {
                padding-right: 1rem;
                width: auto;
                max-width: 67%;
                max-height: 365px;
                margin-bottom: 1rem;

                .slick-autoplay-toggle-button {
                    display: none;
                }

                .slick-prev {
                    padding-left: 3rem;
                    z-index: 1;
                }

                .slick-next {
                    padding-right: 4rem;
                    z-index: 1;
                }

                .slick-arrow {
                    top: 40%;
                }

                .item {
                    position: relative;
                    width: 100%;

                    img {
                        width: 100%;
                        aspect-ratio: 2/1;
                        object-fit: contain;
                        height: auto;
                    }

                    #carousel-caption {
                        background: rgba(0,0,0,0.75);
                        color: $white;
                        padding: 1rem;
                        position: absolute;
                        bottom: 0;

                        p {
                            margin-bottom: 0;
                        }
                    }
                }

                img {
                    min-height: 365px;
                }

                @media (max-width:768px) {
                    padding-right: 0;
                    max-width: 100%;
                }
            }

            #map-container {
                .map {
                    height: 95%;

                    iframe {
                        width: 100%;
                        height: -webkit-fill-available;
                    }
                }

                @media (max-width: 768px) {
                    padding-bottom: 1rem;

                    .map {
                        height: 225px;
                    }
                }
            }

            #static-containers {
                height: fit-content;
                z-index: 1;

                .static-container {
                    background-color: $beige-bg;
                    height: auto;
                    width: 32%;

                    a, a:hover {
                        color: $red;
                        text-decoration: underline;
                    }

                    .static-img {
                        width: -webkit-fill-available;
                        max-width: 125px;

                        img {
                            height: 100%;
                        }

                        @media (max-width: 1200px) {
                            display: none;
                        }
                    }

                    @media (max-width: 1200px) {
                        padding: 0 1rem;
                    }
                }

                @media (max-width: 768px) {
                    flex-direction: column !important;

                    .static-container {
                        width: 100%;
                    }
                }
            }

            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
}

// Footer customizations
footer {
    min-height: 40px;
    background-color: $beige-bg;
    line-height: 1rem;

    h3 {
        color: $red;
        font-weight: 450;
    }

    p {
        margin-bottom: 0.5rem;

        a {
            color: $black;

            &:hover {
                color: $red;
                text-decoration: none;
            }
        }
    }

    img {
        max-width: 100%;
    }

    .subscribe {
        background-color: $red;
        color: $white;

        &:hover {
            background-color: $white-bg;
            color: $black;
        }
    }

    .logo-kule-folklore-centre {
        max-width: 100px;
    }

    .logo-atom {
        width: 170px;
    }

    .logo-ualberta-faculty-of-arts-logo {
        max-width: 375px;

        @media (max-width: 992px) {
            max-width: 350px;
        }
    }

    @media (max-width: 768px) {
        .footer-container {
            flex-direction: column !important;
        }

        .d-flex, .w-40 {
            display: flex;
            justify-content: center !important;
            width: 100% !important;
        }

        .col-xl-6 .flex-row {
            flex-direction: column !important;
            margin-bottom: 1rem;
        }

        .logo-atom {
            width: 40%;
        }

        .logo-ualberta-faculty-of-arts-logo {
            max-width: 100%;
        }
    }
}