$black: #333;
$white: #fff;
$white-bg: #e6e6e6;
$hover-white: rgba(255,255,255,0.4);
$gray: #999;
$gray-text: #bbb;
$orange: #e07c00;
$red: #a12b2a;
$red-highlight: #ffffff66;
$beige-bg: #e7e0d6;
$green-bg: #68813c;